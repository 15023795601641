const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'fetch',
};

const defaultOptions: RequestInit = {
  headers,
  credentials: 'same-origin',
};

export class ResponseError extends Error {
  status: number;

  data: unknown;

  constructor(message: string, status: number, data: unknown) {
    super(message);
    this.name = 'ResponseError';
    this.status = status;
    this.data = data;
  }
}

const handler = async (res: Response) => {
  const data = await res.json();
  if (res.ok) {
    return data;
  }
  throw new ResponseError(data.message, res.status, data);
};

const get = (path: string, options: RequestInit = {}) =>
  fetch(path, {
    ...defaultOptions,
    ...options,
    method: 'GET',
  }).then(handler);

const post = (path: string, body: unknown = {}) =>
  fetch(path, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(body),
  }).then(handler);

const fetcher = {
  get,
  post,
};

export { fetcher };
