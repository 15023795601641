import 'styles/globals.css';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AuthProvider from '@/providers/AuthProvider';
import { useEffect, useMemo, useState } from 'react';
import { setUpZod } from '@/lib/validation';
import Head from 'next/head';
import UAParser from 'ua-parser-js';
import { useTranslation } from '@/hooks/i18n';
import { useInitAmplitude } from '@/hooks/amplitude';
import { ToastProvider } from '@toyokumo/kintoneapp-ui';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5分
      gcTime: 1000 * 60 * 5, // 5分
      refetchOnWindowFocus: false, // 画面にフォーカスが当たった時にstaleしていたら再取得
      retry: 0,
      networkMode: 'always',
      // refetchOnReconnectはデフォルトはtrueだが、networkModeがalwaysの場合はfalseになるのでtrueを指定している。
      // 接続状態がオンラインになった時にstaleしていたら再取得する
      refetchOnReconnect: true,
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

const useIsIos = () =>
  useMemo(() => {
    const uaParser = new UAParser();
    const os = uaParser.getOS();
    return os.name?.toLowerCase() === 'ios';
  }, []);

const API_MOCKING = process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';

function MyApp({ Component, pageProps }: AppProps) {
  // zodのカスタムエラーメッセージを登録
  const translation = useTranslation();
  useEffect(() => {
    setUpZod(translation);
  }, [translation]);
  const isIos = useIsIos();

  useInitAmplitude();

  const isMynumberLoginPage = Component.displayName === 'MynumberLogin';

  const [shouldRender, setShouldRender] = useState(!API_MOCKING);

  // cf. https://github.com/mswjs/msw/discussions/1049
  //     https://mswjs.io/docs/recipes/deferred-mounting
  // Deferred mounting の仕組みを入れないと画面ロード時にGraphQLにリクエストを投げるパターンで
  // Service Worker 登録前にリクエストを投げてしまうことがある
  useEffect(() => {
    async function setupMocks() {
      const { initMocks } = await import('../mocks');
      await initMocks();
      setShouldRender(true);
    }

    if (API_MOCKING) {
      setupMocks();
    }
  }, []);

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Toyokumo kintoneAppアカウント</title>

        {/* iOSの配慮で、16px未満のinputにフォーカスした際に自動的にズームされてしまう挙動を防いでいる
        デザインチームとの相談の結果、デザイン的にはそれ以下（14px）で問題ないと判断している。
        参考: https://zenn.dev/rhirayamaaan/articles/f0209ad6574ed4

        また、_documentにviewportのmetaタグを置くべきではないようなので、ここに置いている
        参考: https://nextjs.org/docs/messages/no-document-viewport-meta
        */}
        {isIos && <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0" />}
      </Head>

      <QueryClientProvider client={queryClient}>
        {isMynumberLoginPage ? (
          <ToastProvider>
            <Component {...pageProps} />
          </ToastProvider>
        ) : (
          <AuthProvider>
            <ToastProvider>
              <Component {...pageProps} />
            </ToastProvider>
          </AuthProvider>
        )}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
