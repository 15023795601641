import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** アカウント */
export type Account = AccountInterface & {
  __typename?: 'Account';
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  subAccount?: Maybe<SubAccount>;
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

/**
 * アカウントの共通型。
 * Toyokukumo kintoneAppアカウントでは複数製品のAccountを扱うため共通の型を定義する。
 */
export type AccountInterface = {
  /** 製品によってはDBのスキーマ上だとnullableなので、nullを返さないように注意する */
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

/** サブ管理者を追加するリクエストをした時の戻り値 */
export type AddSubAccountPayload = SubAccount | UserError;

export enum ElectronicContractElementType {
  Stamp = 'STAMP',
  Text = 'TEXT',
}

/** electronic_contract_requestテーブルに対応している */
export type ElectronicContractRequest = {
  __typename?: 'ElectronicContractRequest';
  /** 紐づいている合意締結証明書。署名が完了するまではnull。 */
  agreementPdf?: Maybe<ElectronicContractRequestAgreementPdf>;
  /** 紐づいているecr_cancel */
  cancel?: Maybe<ElectronicContractRequestCancel>;
  /** 取引金額(bigintなのでstring定義にしている) */
  contractAmount?: Maybe<Scalars['String']['output']>;
  /** 契約締結日（ISO8601形式） */
  contractDate?: Maybe<Scalars['String']['output']>;
  /** 契約終了日（ISO8601形式） */
  contractEndDate?: Maybe<Scalars['String']['output']>;
  /** 契約開始日（ISO8601形式） */
  contractStartDate?: Maybe<Scalars['String']['output']>;
  /** 依頼が作成された日時（ISO8601形式） */
  createdAt: Scalars['String']['output'];
  /** 紐づいている依頼先のリスト */
  destinations: Array<ElectronicContractRequestDestination>;
  /** ledgerBookTitleとは異なるカスタムの書類名 */
  documentName: Scalars['String']['output'];
  /** 紐づいている署名事項のリスト */
  elements: Array<ElectronicContractRequestElement>;
  /** 主キー */
  id: Scalars['Int']['output'];
  /** 依頼先がログインを必須とするか */
  isUseAuth: Scalars['Boolean']['output'];
  /** 依頼送信時に添えるメッセージ */
  message?: Maybe<Scalars['String']['output']>;
  /** 依頼者の会社名 */
  requesterCompany?: Maybe<Scalars['String']['output']>;
  /** 依頼者の名前 */
  requesterName: Scalars['String']['output'];
  /** 契約の進捗を示すステータス */
  status: ElectronicContractRequestStatus;
};

/**
 * 合意締結証明書のデータ
 * electronic_contract_request_agreement_pdfテーブルに対応している
 */
export type ElectronicContractRequestAgreementPdf = {
  __typename?: 'ElectronicContractRequestAgreementPdf';
  /** 作成日時（ISO8601形式） */
  createdAt: Scalars['String']['output'];
  /** 合意締結証明書DL用のPresignedUrl */
  fileUrl: PresignedUrl;
};

/**
 * 電子契約依頼がキャンセルされた時にinsertされるテーブル
 * electronic_contract_request_cancelテーブルに対応している
 */
export type ElectronicContractRequestCancel = {
  __typename?: 'ElectronicContractRequestCancel';
  /** キャンセルした日時（ISO8601形式） */
  canceledAt: Scalars['String']['output'];
  /** PC管理者が取り消した場合はtrue、kintone運用担当者が取り消した場合はfalse */
  isCanceledByAdmin: Scalars['Boolean']['output'];
};

/**
 * electronic_contract_request_destinationテーブルに対応している
 * url_tokenは返すべきでないので省略している
 */
export type ElectronicContractRequestDestination = {
  __typename?: 'ElectronicContractRequestDestination';
  /** 紐づいているecrd_agreement */
  agreement?: Maybe<ElectronicContractRequestDestinationAgreement>;
  /** 依頼先の会社名 */
  companyName?: Maybe<Scalars['String']['output']>;
  /** 紐づいているElectronicContractRequest */
  electronicContractRequest: ElectronicContractRequest;
  /** 依頼先のメールアドレス。送信者(sort:0)がkintoneにemailを登録していない場合はnullになる。 */
  email?: Maybe<Scalars['String']['output']>;
  /** 期限切れとなる日時（ISO8601形式） */
  expiredAt?: Maybe<Scalars['String']['output']>;
  /** 主キー */
  id: Scalars['Int']['output'];
  /** 依頼先の名前 */
  name: Scalars['String']['output'];
  /** 紐づいているecrd_rejection */
  rejection?: Maybe<ElectronicContractRequestDestinationRejection>;
  /** 並び替えの順番(1 based index). unique. */
  sort: Scalars['Int']['output'];
  /** 依頼先の契約に対する操作状況 */
  status: ElectronicContractRequestDestinationStatus;
};

/** electronic_contract_request_destination_agreementテーブルに対応している */
export type ElectronicContractRequestDestinationAgreement = {
  __typename?: 'ElectronicContractRequestDestinationAgreement';
  /** 作成日時（ISO8601形式） */
  createdAt: Scalars['String']['output'];
  /** kintoneフィールドへの保存へ失敗した場合などのメッセージ。ユーザーに表示する。 */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** 同意時のPDFのPresigned URL */
  fileUrl: PresignedUrl;
};

/** electronic_contract_request_destination_rejectedテーブルに対応している */
export type ElectronicContractRequestDestinationRejection = {
  __typename?: 'ElectronicContractRequestDestinationRejection';
  /** 作成日時（ISO8601形式） */
  createdAt: Scalars['String']['output'];
  /** 依頼先ユーザーが入力した却下理由 */
  reason?: Maybe<Scalars['String']['output']>;
};

export enum ElectronicContractRequestDestinationStatus {
  /** 受信者画面を表示済み */
  Confirmed = 'CONFIRMED',
  /** メール開封済み */
  Opened = 'OPENED',
  /** 却下済み */
  Rejected = 'REJECTED',
  /** 依頼メール送信済み */
  Sent = 'SENT',
  /** 合意済み */
  Signed = 'SIGNED',
  /** 前の人が未合意 */
  Waiting = 'WAITING',
}

/**
 * 署名事項
 * electronic_contract_request_elementテーブルに対応している
 */
export type ElectronicContractRequestElement = {
  __typename?: 'ElectronicContractRequestElement';
  /** 紐づいている依頼先 */
  destination: ElectronicContractRequestDestination;
  /** 主キー */
  id: Scalars['Int']['output'];
  /** 描画されるページ(1 based index) */
  page: Scalars['Int']['output'];
  /** 入力情報を保存するフィールドコード(128文字以内) */
  saveFieldCode?: Maybe<Scalars['String']['output']>;
  /** 紐づいているテキストスタイル(type=TEXT以外はnull) */
  text?: Maybe<ElectronicContractRequestElementText>;
  /** 種類 */
  type: ElectronicContractElementType;
  /** 紐づいている入力された内容(入力して署名されるまではnull) */
  value?: Maybe<ElectronicContractRequestElementValue>;
};

/**
 * テキストタイプの署名事項の入力情報
 * electronic_contract_request_element_textテーブルに対応している
 */
export type ElectronicContractRequestElementText = {
  __typename?: 'ElectronicContractRequestElementText';
  /** 項目名(128文字以下) */
  name: Scalars['String']['output'];
  /** 補足情報(128文字以下) */
  supplementMessage?: Maybe<Scalars['String']['output']>;
};

/**
 * 署名事項の入力内容を保存するテーブル
 * electronic_contract_request_element_valueテーブルに対応している
 */
export type ElectronicContractRequestElementValue = {
  __typename?: 'ElectronicContractRequestElementValue';
  /** 入力値(256文字以下) */
  value: Scalars['String']['output'];
};

export type ElectronicContractRequestForAccountWithUrlToken = {
  __typename?: 'ElectronicContractRequestForAccountWithUrlToken';
  /** リクエストしたアカウントが署名するためのurlToken。署名可能な状態でない場合はnull */
  ownUrlToken?: Maybe<Scalars['String']['output']>;
  value?: Maybe<ElectronicContractRequest>;
};

export enum ElectronicContractRequestStatus {
  /** キャンセル済み */
  Canceled = 'CANCELED',
  /** 期限切れ */
  Expired = 'EXPIRED',
  /** 却下済み */
  Rejected = 'REJECTED',
  /** 依頼メール送信済み */
  Sent = 'SENT',
  /** 合意済み */
  Signed = 'SIGNED',
  /** 依頼者署名待ち */
  Waiting = 'WAITING',
}

/** エラーの詳細。どの箇所でどんなエラーが出ているか。 */
export type ErrorField = {
  __typename?: 'ErrorField';
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** 招待中のステータス定義 */
export enum InvitationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

/** アカウントのうち、サブ管理者から参照されるときのアカウント定義 */
export type MainAccount = AccountInterface & {
  __typename?: 'MainAccount';
  company: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * 自分自身のアドレスで持っている環境にサブ管理者を追加する。
   * 自分自身のアドレスと同じメールアドレスでは追加できない。
   * 追加したサブ管理者は PENDING として追加され、account.kintoneapp.comで承諾することで認可される。
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  addSubAccount: AddSubAccountPayload;
  /**
   * 自分自身のアドレスがサブ管理者である環境に対して、招待の承諾を行う
   * サブ管理者のステータスをPENDINGからAPPROVEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  approveSubAccount: UpdateSubAccountPayload;
  /**
   * 自分自身のアドレスで持っている環境のサブ管理者を削除する。
   * 削除対象が存在しない場合にUserErrorが返る
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  deleteSubAccount?: Maybe<UserError>;
  /**
   * 自分自身のアドレスがサブ管理者である環境に対して、招待の辞退を行う
   * サブ管理者のステータスをPENDINGからREJECTEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  rejectSubAccount: UpdateSubAccountPayload;
  /**
   * 自分自身の所有している環境のアカウントの所有者をサブ管理者に変更する
   * 元の自分自身はサブ管理者になる
   * 既にサブ管理者がサブ管理者自身の環境を持っていた場合は変更できない
   *
   * role :has-main-account
   */
  swapOwnerWithSubAccount?: Maybe<UserError>;
};

export type MutationAddSubAccountArgs = {
  email: Scalars['String']['input'];
};

export type MutationApproveSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

export type MutationRejectSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

/** 全kintone連携製品共通の型定義 */
export enum Plan {
  Light = 'LIGHT',
  Premium = 'PREMIUM',
  Professional = 'PROFESSIONAL',
  Standard = 'STANDARD',
  Trial = 'TRIAL',
}

/**
 * S3の署名付きURL
 * file_key系カラムはこのtypeを使用してpresignedUrlを返す
 */
export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  /** 署名付きURL */
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * 自分自身のアドレスに紐づくアカウント情報を取得する
   * アカウントの選択は関係ないことに注意が必要
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  account?: Maybe<Account>;
  /** electronicContractRequestをidで指定して取得する */
  electronicContractRequestFromAccount: ElectronicContractRequestForAccountWithUrlToken;
  /** accountに紐づくelectronicContractRequestを全件取得する */
  electronicContractRequestsFromAccount: Array<ElectronicContractRequest>;
  /** ログイン中のkAppAuthAccountを依頼先に持つelectronicContractRequestのうち、自身の確認待ちのもの１件を取得する */
  latestSignableElectronicContractRequestFromAccount?: Maybe<SignableElectronicContractRequestForAccountWithUrlToken>;
  /**
   * アカウント選択で選択したアカウント情報を取得する
   * role :account
   */
  mainAccount: Account;
  /**
   * 自分自身のアドレスがサブ管理者であるリストを取得する
   * サブ管理者を登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待未承諾であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  pendingSubAccounts: Array<SubAccount>;
  /**
   * 自分自身のアドレスがサブ管理者であるリストを取得する
   * サブ管理者を登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待承諾済であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  subAccounts: Array<SubAccount>;
};

export type QueryElectronicContractRequestFromAccountArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type SignableElectronicContractRequestForAccountWithUrlToken = {
  __typename?: 'SignableElectronicContractRequestForAccountWithUrlToken';
  /** accountから署名画面へ遷移するためのurlToken */
  ownUrlToken: Scalars['String']['output'];
  value: ElectronicContractRequest;
};

/**
 * サブ管理者
 * アカウントは1つだけサブ管理者を設定できる
 */
export type SubAccount = {
  __typename?: 'SubAccount';
  email: Scalars['String']['output'];
  mainAccount: MainAccount;
  status: InvitationStatus;
};

/** 招待されているサブ管理者の承諾または辞退をした時の戻り値 */
export type UpdateSubAccountPayload = SubAccount | UserError;

/**
 * ユーザーの入力によって生じたエラーを示す
 * 原則としてユーザーの入力を修正することでリクエストは成功する可能性がある
 * バリデーションエラーなどドメインロジックに関係するエラー、復帰可能なエラー、などを伴う処理はこれの union を返す
 * mutaion では基本的に使うべき
 * query では引数の検証などが必要な時は使うべき。引数が無い場合は使わないこともある
 */
export type UserError = {
  __typename?: 'UserError';
  /**
   * エラーの種別。主にプログラムで処理を分ける用
   * TODO: 決まってきたら Enum にする
   */
  code: Scalars['String']['output'];
  /**
   * エラーの詳細の一覧。
   * 例： [{ name: "email", message: "emailの仕様に合ってません" }, { name: token, message: "期限切れです" }]
   */
  fields: Array<ErrorField>;
  /**
   * ユーザーに見せるためのエラーメッセージ
   * 例: 入力されたEmailが正しくありません。
   */
  message: Scalars['String']['output'];
};

export type AddSubAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type AddSubAccountMutation = {
  __typename?: 'Mutation';
  addSubAccount:
    | { __typename: 'SubAccount'; email: string }
    | {
        __typename: 'UserError';
        code: string;
        message: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type ApproveSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type ApproveSubAccountMutation = {
  __typename?: 'Mutation';
  approveSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type DeleteSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteSubAccountMutation = {
  __typename?: 'Mutation';
  deleteSubAccount?: { __typename: 'UserError'; code: string; message: string } | null;
};

export type MainAndSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type MainAndSubAccountsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    rawId: string;
    name: string;
    email: string;
    plan: Plan;
    company: string;
    trialExpiresAt?: string | null;
    registeredAt: string;
    subAccount?: { __typename?: 'SubAccount'; email: string; status: InvitationStatus } | null;
  } | null;
  subAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type PendingSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingSubAccountsQuery = {
  __typename?: 'Query';
  pendingSubAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    status: InvitationStatus;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type RejectSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type RejectSubAccountMutation = {
  __typename?: 'Mutation';
  rejectSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type SwapOwnerWithSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type SwapOwnerWithSubAccountMutation = {
  __typename?: 'Mutation';
  swapOwnerWithSubAccount?: {
    __typename: 'UserError';
    code: string;
    message: string;
    fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
  } | null;
};

export type EcrDestinationForHistoryDetailFragment = {
  __typename?: 'ElectronicContractRequestDestination';
  sort: number;
  name: string;
  companyName?: string | null;
  email?: string | null;
  status: ElectronicContractRequestDestinationStatus;
  expiredAt?: string | null;
  agreement?: {
    __typename?: 'ElectronicContractRequestDestinationAgreement';
    createdAt: string;
    fileUrl: { __typename?: 'PresignedUrl'; url: string };
  } | null;
  rejection?: { __typename?: 'ElectronicContractRequestDestinationRejection'; createdAt: string } | null;
};

export type ElectronicContractRequestForDetailPageFragment = {
  __typename?: 'ElectronicContractRequest';
  id: number;
  documentName: string;
  isUseAuth: boolean;
  status: ElectronicContractRequestStatus;
  contractDate?: string | null;
  contractStartDate?: string | null;
  contractEndDate?: string | null;
  contractAmount?: string | null;
  agreementPdf?: {
    __typename?: 'ElectronicContractRequestAgreementPdf';
    fileUrl: { __typename?: 'PresignedUrl'; url: string };
  } | null;
  destinations: Array<{
    __typename?: 'ElectronicContractRequestDestination';
    sort: number;
    name: string;
    companyName?: string | null;
    email?: string | null;
    status: ElectronicContractRequestDestinationStatus;
    expiredAt?: string | null;
    agreement?: {
      __typename?: 'ElectronicContractRequestDestinationAgreement';
      createdAt: string;
      fileUrl: { __typename?: 'PresignedUrl'; url: string };
    } | null;
    rejection?: { __typename?: 'ElectronicContractRequestDestinationRejection'; createdAt: string } | null;
  }>;
  elements: Array<{
    __typename?: 'ElectronicContractRequestElement';
    id: number;
    type: ElectronicContractElementType;
    text?: { __typename?: 'ElectronicContractRequestElementText'; name: string } | null;
    value?: { __typename?: 'ElectronicContractRequestElementValue'; value: string } | null;
    destination: { __typename?: 'ElectronicContractRequestDestination'; name: string };
  }>;
};

export type GetElectronicContractRequestQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetElectronicContractRequestQuery = {
  __typename?: 'Query';
  electronicContractRequestFromAccount: {
    __typename?: 'ElectronicContractRequestForAccountWithUrlToken';
    ownUrlToken?: string | null;
    value?: {
      __typename?: 'ElectronicContractRequest';
      id: number;
      documentName: string;
      isUseAuth: boolean;
      status: ElectronicContractRequestStatus;
      contractDate?: string | null;
      contractStartDate?: string | null;
      contractEndDate?: string | null;
      contractAmount?: string | null;
      agreementPdf?: {
        __typename?: 'ElectronicContractRequestAgreementPdf';
        fileUrl: { __typename?: 'PresignedUrl'; url: string };
      } | null;
      destinations: Array<{
        __typename?: 'ElectronicContractRequestDestination';
        sort: number;
        name: string;
        companyName?: string | null;
        email?: string | null;
        status: ElectronicContractRequestDestinationStatus;
        expiredAt?: string | null;
        agreement?: {
          __typename?: 'ElectronicContractRequestDestinationAgreement';
          createdAt: string;
          fileUrl: { __typename?: 'PresignedUrl'; url: string };
        } | null;
        rejection?: { __typename?: 'ElectronicContractRequestDestinationRejection'; createdAt: string } | null;
      }>;
      elements: Array<{
        __typename?: 'ElectronicContractRequestElement';
        id: number;
        type: ElectronicContractElementType;
        text?: { __typename?: 'ElectronicContractRequestElementText'; name: string } | null;
        value?: { __typename?: 'ElectronicContractRequestElementValue'; value: string } | null;
        destination: { __typename?: 'ElectronicContractRequestDestination'; name: string };
      }>;
    } | null;
  };
};

export type ElectronicContractRequestForListTableRowFragment = {
  __typename?: 'ElectronicContractRequest';
  id: number;
  documentName: string;
  requesterName: string;
  requesterCompany?: string | null;
  contractDate?: string | null;
  contractStartDate?: string | null;
  contractEndDate?: string | null;
  contractAmount?: string | null;
  status: ElectronicContractRequestStatus;
  destinations: Array<{
    __typename?: 'ElectronicContractRequestDestination';
    sort: number;
    status: ElectronicContractRequestDestinationStatus;
    expiredAt?: string | null;
    agreement?: { __typename?: 'ElectronicContractRequestDestinationAgreement'; createdAt: string } | null;
    rejection?: { __typename?: 'ElectronicContractRequestDestinationRejection'; createdAt: string } | null;
  }>;
  cancel?: { __typename?: 'ElectronicContractRequestCancel'; canceledAt: string } | null;
};

export type GetElectronicContractRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetElectronicContractRequestsQuery = {
  __typename?: 'Query';
  electronicContractRequestsFromAccount: Array<{
    __typename?: 'ElectronicContractRequest';
    id: number;
    documentName: string;
    requesterName: string;
    requesterCompany?: string | null;
    contractDate?: string | null;
    contractStartDate?: string | null;
    contractEndDate?: string | null;
    contractAmount?: string | null;
    status: ElectronicContractRequestStatus;
    destinations: Array<{
      __typename?: 'ElectronicContractRequestDestination';
      sort: number;
      status: ElectronicContractRequestDestinationStatus;
      expiredAt?: string | null;
      agreement?: { __typename?: 'ElectronicContractRequestDestinationAgreement'; createdAt: string } | null;
      rejection?: { __typename?: 'ElectronicContractRequestDestinationRejection'; createdAt: string } | null;
    }>;
    cancel?: { __typename?: 'ElectronicContractRequestCancel'; canceledAt: string } | null;
  }>;
};

export type ElectronicContractRequestForBannerFragment = {
  __typename?: 'ElectronicContractRequest';
  requesterName: string;
  documentName: string;
  destinations: Array<{
    __typename?: 'ElectronicContractRequestDestination';
    email?: string | null;
    expiredAt?: string | null;
  }>;
};

export type LatestSignableElectronicContractRequestFragment = {
  __typename?: 'SignableElectronicContractRequestForAccountWithUrlToken';
  ownUrlToken: string;
  value: {
    __typename?: 'ElectronicContractRequest';
    requesterName: string;
    documentName: string;
    destinations: Array<{
      __typename?: 'ElectronicContractRequestDestination';
      email?: string | null;
      expiredAt?: string | null;
    }>;
  };
};

export type GetLatestSignableElectronicContractRequestQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestSignableElectronicContractRequestQuery = {
  __typename?: 'Query';
  latestSignableElectronicContractRequestFromAccount?: {
    __typename?: 'SignableElectronicContractRequestForAccountWithUrlToken';
    ownUrlToken: string;
    value: {
      __typename?: 'ElectronicContractRequest';
      requesterName: string;
      documentName: string;
      destinations: Array<{
        __typename?: 'ElectronicContractRequestDestination';
        email?: string | null;
        expiredAt?: string | null;
      }>;
    };
  } | null;
};

export const EcrDestinationForHistoryDetailFragmentDoc = gql`
  fragment ECRDestinationForHistoryDetail on ElectronicContractRequestDestination {
    sort
    name
    companyName
    email
    status
    expiredAt
    agreement {
      fileUrl {
        url
      }
      createdAt
    }
    rejection {
      createdAt
    }
  }
`;
export const ElectronicContractRequestForDetailPageFragmentDoc = gql`
  fragment ElectronicContractRequestForDetailPage on ElectronicContractRequest {
    id
    documentName
    isUseAuth
    status
    contractDate
    contractStartDate
    contractEndDate
    contractAmount
    agreementPdf {
      fileUrl {
        url
      }
    }
    destinations {
      ...ECRDestinationForHistoryDetail
    }
    elements {
      id
      type
      text {
        name
      }
      value {
        value
      }
      destination {
        name
      }
    }
  }
  ${EcrDestinationForHistoryDetailFragmentDoc}
`;
export const ElectronicContractRequestForListTableRowFragmentDoc = gql`
  fragment ElectronicContractRequestForListTableRow on ElectronicContractRequest {
    id
    documentName
    requesterName
    requesterCompany
    contractDate
    contractStartDate
    contractEndDate
    contractAmount
    status
    destinations {
      sort
      status
      expiredAt
      agreement {
        createdAt
      }
      rejection {
        createdAt
      }
    }
    cancel {
      canceledAt
    }
  }
`;
export const ElectronicContractRequestForBannerFragmentDoc = gql`
  fragment ElectronicContractRequestForBanner on ElectronicContractRequest {
    requesterName
    documentName
    destinations {
      email
      expiredAt
    }
  }
`;
export const LatestSignableElectronicContractRequestFragmentDoc = gql`
  fragment LatestSignableElectronicContractRequest on SignableElectronicContractRequestForAccountWithUrlToken {
    value {
      ...ElectronicContractRequestForBanner
    }
    ownUrlToken
  }
  ${ElectronicContractRequestForBannerFragmentDoc}
`;
export const AddSubAccountDocument = gql`
  mutation addSubAccount($email: String!) {
    addSubAccount(email: $email) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        code
        message
        fields {
          name
          message
        }
      }
    }
  }
`;
export const ApproveSubAccountDocument = gql`
  mutation approveSubAccount($mainAccountEmail: String!) {
    approveSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const DeleteSubAccountDocument = gql`
  mutation deleteSubAccount {
    deleteSubAccount {
      __typename
      code
      message
    }
  }
`;
export const MainAndSubAccountsDocument = gql`
  query mainAndSubAccounts {
    account {
      rawId
      name
      email
      plan
      company
      trialExpiresAt
      registeredAt
      subAccount {
        email
        status
      }
    }
    subAccounts {
      email
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const PendingSubAccountsDocument = gql`
  query pendingSubAccounts {
    pendingSubAccounts {
      email
      status
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const RejectSubAccountDocument = gql`
  mutation rejectSubAccount($mainAccountEmail: String!) {
    rejectSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const SwapOwnerWithSubAccountDocument = gql`
  mutation swapOwnerWithSubAccount {
    swapOwnerWithSubAccount {
      __typename
      code
      message
      fields {
        name
        message
      }
    }
  }
`;
export const GetElectronicContractRequestDocument = gql`
  query getElectronicContractRequest($id: Int!) {
    electronicContractRequestFromAccount(id: $id) {
      value {
        ...ElectronicContractRequestForDetailPage
      }
      ownUrlToken
    }
  }
  ${ElectronicContractRequestForDetailPageFragmentDoc}
`;
export const GetElectronicContractRequestsDocument = gql`
  query getElectronicContractRequests {
    electronicContractRequestsFromAccount {
      ...ElectronicContractRequestForListTableRow
    }
  }
  ${ElectronicContractRequestForListTableRowFragmentDoc}
`;
export const GetLatestSignableElectronicContractRequestDocument = gql`
  query getLatestSignableElectronicContractRequest {
    latestSignableElectronicContractRequestFromAccount {
      ...LatestSignableElectronicContractRequest
    }
  }
  ${LatestSignableElectronicContractRequestFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addSubAccount(
      variables: AddSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddSubAccountMutation>(AddSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addSubAccount',
        'mutation',
        variables,
      );
    },
    approveSubAccount(
      variables: ApproveSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ApproveSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ApproveSubAccountMutation>(ApproveSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'approveSubAccount',
        'mutation',
        variables,
      );
    },
    deleteSubAccount(
      variables?: DeleteSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteSubAccountMutation>(DeleteSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteSubAccount',
        'mutation',
        variables,
      );
    },
    mainAndSubAccounts(
      variables?: MainAndSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MainAndSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MainAndSubAccountsQuery>(MainAndSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'mainAndSubAccounts',
        'query',
        variables,
      );
    },
    pendingSubAccounts(
      variables?: PendingSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PendingSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PendingSubAccountsQuery>(PendingSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'pendingSubAccounts',
        'query',
        variables,
      );
    },
    rejectSubAccount(
      variables: RejectSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RejectSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RejectSubAccountMutation>(RejectSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'rejectSubAccount',
        'mutation',
        variables,
      );
    },
    swapOwnerWithSubAccount(
      variables?: SwapOwnerWithSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwapOwnerWithSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SwapOwnerWithSubAccountMutation>(SwapOwnerWithSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'swapOwnerWithSubAccount',
        'mutation',
        variables,
      );
    },
    getElectronicContractRequest(
      variables: GetElectronicContractRequestQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetElectronicContractRequestQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetElectronicContractRequestQuery>(GetElectronicContractRequestDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getElectronicContractRequest',
        'query',
        variables,
      );
    },
    getElectronicContractRequests(
      variables?: GetElectronicContractRequestsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetElectronicContractRequestsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetElectronicContractRequestsQuery>(GetElectronicContractRequestsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getElectronicContractRequests',
        'query',
        variables,
      );
    },
    getLatestSignableElectronicContractRequest(
      variables?: GetLatestSignableElectronicContractRequestQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetLatestSignableElectronicContractRequestQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetLatestSignableElectronicContractRequestQuery>(
            GetLatestSignableElectronicContractRequestDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'getLatestSignableElectronicContractRequest',
        'query',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { email } = variables;
 *     return HttpResponse.json({
 *       data: { addSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAddSubAccountMutation = (
  resolver: GraphQLResponseResolver<AddSubAccountMutation, AddSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<AddSubAccountMutation, AddSubAccountMutationVariables>('addSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockApproveSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { approveSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockApproveSubAccountMutation = (
  resolver: GraphQLResponseResolver<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>(
    'approveSubAccount',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { deleteSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteSubAccountMutation = (
  resolver: GraphQLResponseResolver<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>('deleteSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMainAndSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { account, subAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMainAndSubAccountsQuery = (
  resolver: GraphQLResponseResolver<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>('mainAndSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPendingSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { pendingSubAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPendingSubAccountsQuery = (
  resolver: GraphQLResponseResolver<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>('pendingSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRejectSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { rejectSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRejectSubAccountMutation = (
  resolver: GraphQLResponseResolver<RejectSubAccountMutation, RejectSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<RejectSubAccountMutation, RejectSubAccountMutationVariables>('rejectSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSwapOwnerWithSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { swapOwnerWithSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSwapOwnerWithSubAccountMutation = (
  resolver: GraphQLResponseResolver<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>(
    'swapOwnerWithSubAccount',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetElectronicContractRequestQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { electronicContractRequestFromAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetElectronicContractRequestQuery = (
  resolver: GraphQLResponseResolver<GetElectronicContractRequestQuery, GetElectronicContractRequestQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GetElectronicContractRequestQuery, GetElectronicContractRequestQueryVariables>(
    'getElectronicContractRequest',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetElectronicContractRequestsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { electronicContractRequestsFromAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetElectronicContractRequestsQuery = (
  resolver: GraphQLResponseResolver<GetElectronicContractRequestsQuery, GetElectronicContractRequestsQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GetElectronicContractRequestsQuery, GetElectronicContractRequestsQueryVariables>(
    'getElectronicContractRequests',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLatestSignableElectronicContractRequestQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { latestSignableElectronicContractRequestFromAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetLatestSignableElectronicContractRequestQuery = (
  resolver: GraphQLResponseResolver<
    GetLatestSignableElectronicContractRequestQuery,
    GetLatestSignableElectronicContractRequestQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    GetLatestSignableElectronicContractRequestQuery,
    GetLatestSignableElectronicContractRequestQueryVariables
  >('getLatestSignableElectronicContractRequest', resolver, options);
