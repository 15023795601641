import { useAuthContext, useSelectMainAccount } from '@/hooks/auth';
import type {
  AddSubAccountMutation,
  ApproveSubAccountMutation,
  DeleteSubAccountMutation,
  InvitationStatus,
  MainAndSubAccountsQuery,
  RejectSubAccountMutation,
  SwapOwnerWithSubAccountMutation,
  Plan,
} from '@/generated/fb-graphql-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { ClientError } from 'graphql-request';
import { captureException } from '@sentry/nextjs';
import { AUTHORIZATION_ERROR, INTERNAL_SERVER_ERROR, UNEXPECTED_ERROR } from '@/lib/validation';
import { useCallback, useEffect, useMemo } from 'react';
import { isAccountExpired } from '@/lib/account';
import { dcSdk, fbSdk, kbSdk, kmSdk, kvSdk, pcSdk } from '@/lib/graphql-client';
import { uniqBy } from 'rambda';
import type { ProductName } from '@/lib/product';
import { useRouter } from 'next/router';

// GraphQLのミューテーションに失敗した時のonError用のハンドラ。UserErrorはonSuccessで拾うので別。
// 現状はどの製品でどの種類の認可エラーが起こったかの区別がつかないので共通した処理をおこなっている。
export const useOnGraphQLMutationError = (onError: (message: string) => void) =>
  useCallback(
    (clientError: ClientError) => {
      const graphqlErrors = clientError.response?.errors;
      if (!graphqlErrors) {
        onError(UNEXPECTED_ERROR);
        // GraphQLの文法エラーや、想定外のエラーの場合は原因不明なのでSentryに報告
        captureException(clientError);
        return;
      }

      // 認可エラー・サーバーエラーが起こった場合。
      const hasAuthorizationError = graphqlErrors.some((e) => e.extensions.code === 'AUTHORIZATION_ERROR');
      const hasInternalServerError = graphqlErrors.some((e) => e.extensions.code === 'INTERNAL_SERVER_ERROR');
      if (hasAuthorizationError) {
        onError(AUTHORIZATION_ERROR);
      }
      if (hasInternalServerError) {
        onError(INTERNAL_SERVER_ERROR);
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.log(...graphqlErrors);
        }
      }
    },
    [onError],
  );

// 自分自身のアドレスが保有するアカウントと、自分自身のアドレスがサブ管理者であるリストを取得する
const mainAndSubAccountsQueryPrefix = 'mainAndSubAccounts';
const useFBMainAndSubAccounts = (enabled = true) =>
  useQuery({
    queryKey: [mainAndSubAccountsQueryPrefix, 'fb'],
    queryFn: () => fbSdk.mainAndSubAccounts(),
    enabled,
  });
const useKVMainAndSubAccounts = (enabled = true) =>
  useQuery({
    queryKey: [mainAndSubAccountsQueryPrefix, 'kv'],
    queryFn: () => kvSdk.mainAndSubAccounts(),
    enabled,
  });
const useKMMainAndSubAccounts = (enabled = true) =>
  useQuery({
    queryKey: [mainAndSubAccountsQueryPrefix, 'km'],
    queryFn: () => kmSdk.mainAndSubAccounts(),
    enabled,
  });
export const usePCMainAndSubAccounts = (enabled = true) =>
  useQuery({
    queryKey: [mainAndSubAccountsQueryPrefix, 'pc'],
    queryFn: () => pcSdk.mainAndSubAccounts(),
    enabled,
  });
const useDCMainAndSubAccounts = (enabled = true) =>
  useQuery({
    queryKey: [mainAndSubAccountsQueryPrefix, 'dc'],
    queryFn: () => dcSdk.mainAndSubAccounts(),
    enabled,
  });
const useKBMainAndSubAccounts = (enabled = true) =>
  useQuery({
    queryKey: [mainAndSubAccountsQueryPrefix, 'kb'],
    queryFn: () => kbSdk.mainAndSubAccounts(),
    enabled,
  });

export const useMainAndSubAccounts = (enabled = true) => {
  const { data: fbData, isPending: isFBPending } = useFBMainAndSubAccounts(enabled);
  const { data: kvData, isPending: isKVPending } = useKVMainAndSubAccounts(enabled);
  const { data: kmData, isPending: isKMPending } = useKMMainAndSubAccounts(enabled);
  const { data: pcData, isPending: isPCPending } = usePCMainAndSubAccounts(enabled);
  const { data: dcData, isPending: isDCPending } = useDCMainAndSubAccounts(enabled);
  const { data: kbData, isPending: isKBPending } = useKBMainAndSubAccounts(enabled);

  return {
    fb: { data: fbData, isPending: isFBPending },
    kv: { data: kvData, isPending: isKVPending },
    km: { data: kmData, isPending: isKMPending },
    pc: { data: pcData, isPending: isPCPending },
    dc: { data: dcData, isPending: isDCPending },
    kb: { data: kbData, isPending: isKBPending },
    isAnyPending: isFBPending || isKVPending || isKMPending || isPCPending || isDCPending || isKBPending,
  };
};

export type PendingSubAccount = { mainAccountEmail: string; apps: Set<string> };

// 自分自身をサブ管理者として招待しているリストを取得
const pendingSubAccountsQueryPrefix = 'pendingSubAccounts';
export const usePendingSubAccounts = () => {
  const fb = useQuery({
    queryKey: [pendingSubAccountsQueryPrefix, 'fb'],
    queryFn: () => fbSdk.pendingSubAccounts(),
  });
  const kv = useQuery({
    queryKey: [pendingSubAccountsQueryPrefix, 'kv'],
    queryFn: () => kvSdk.pendingSubAccounts(),
  });
  const km = useQuery({
    queryKey: [pendingSubAccountsQueryPrefix, 'km'],
    queryFn: () => kmSdk.pendingSubAccounts(),
  });
  const pc = useQuery({
    queryKey: [pendingSubAccountsQueryPrefix, 'pc'],
    queryFn: () => pcSdk.pendingSubAccounts(),
  });
  const dc = useQuery({
    queryKey: [pendingSubAccountsQueryPrefix, 'dc'],
    queryFn: () => dcSdk.pendingSubAccounts(),
  });
  const kb = useQuery({
    queryKey: [pendingSubAccountsQueryPrefix, 'kb'],
    queryFn: () => kbSdk.pendingSubAccounts(),
  });

  const pendingSubAccounts = useMemo<PendingSubAccount[]>(() => {
    const subs = [
      ...(fb.data?.pendingSubAccounts
        .filter(({ mainAccount }) => !isAccountExpired(mainAccount))
        .map((m) => ({ ...m, app: 'fb' })) || []),
      ...(kv.data?.pendingSubAccounts
        .filter(({ mainAccount }) => !isAccountExpired(mainAccount))
        .map((m) => ({ ...m, app: 'kv' })) || []),
      ...(km.data?.pendingSubAccounts
        .filter(({ mainAccount }) => !isAccountExpired(mainAccount))
        .map((m) => ({ ...m, app: 'km' })) || []),
      ...(pc.data?.pendingSubAccounts
        .filter(({ mainAccount }) => !isAccountExpired(mainAccount))
        .map((m) => ({ ...m, app: 'pc' })) || []),
      ...(dc.data?.pendingSubAccounts
        .filter(({ mainAccount }) => !isAccountExpired(mainAccount))
        .map((m) => ({ ...m, app: 'dc' })) || []),
      ...(kb.data?.pendingSubAccounts
        .filter(({ mainAccount }) => !isAccountExpired(mainAccount))
        .map((m) => ({ ...m, app: 'kb' })) || []),
    ];

    // {招待している人のアドレス: Set<製品>}
    const appsByEmail = new Map<string, Set<string>>();

    subs.forEach((sub) => {
      const apps = appsByEmail.get(sub.mainAccount.email);
      if (apps) {
        apps.add(sub.app);
      } else {
        appsByEmail.set(sub.mainAccount.email, new Set([sub.app]));
      }
    });

    return Array.from(appsByEmail.entries()).map(([mainAccountEmail, apps]) => ({
      mainAccountEmail,
      apps,
    }));
  }, [fb.data, kv.data, km.data, pc.data, dc.data, kb.data]);

  return {
    isPending: fb.isPending || kv.isPending || km.isPending || pc.isPending || dc.isPending || kb.isPending,
    pendingSubAccounts,
  };
};

// 招待の承諾
const approveSubAccountMutationPrefix = 'approveSubAccount';
export const useApproveSubAccount = () => {
  const queryClient = useQueryClient();
  const fb = useMutation({
    mutationKey: [approveSubAccountMutationPrefix, 'fb'],
    mutationFn: fbSdk.approveSubAccount,
  });
  const kv = useMutation({
    mutationKey: [approveSubAccountMutationPrefix, 'kv'],
    mutationFn: kvSdk.approveSubAccount,
  });
  const km = useMutation({
    mutationKey: [approveSubAccountMutationPrefix, 'km'],
    mutationFn: kmSdk.approveSubAccount,
  });
  const pc = useMutation({
    mutationKey: [approveSubAccountMutationPrefix, 'pc'],
    mutationFn: pcSdk.approveSubAccount,
  });
  const dc = useMutation({
    mutationKey: [approveSubAccountMutationPrefix, 'dc'],
    mutationFn: dcSdk.approveSubAccount,
  });
  const kb = useMutation({
    mutationKey: [approveSubAccountMutationPrefix, 'kb'],
    mutationFn: kbSdk.approveSubAccount,
  });

  return useMutation<
    { mutationResult: ApproveSubAccountMutation; productName: ProductName }[],
    ClientError,
    PendingSubAccount,
    unknown
  >({
    mutationFn: ({ mainAccountEmail, apps }) => {
      const promises: Promise<{ mutationResult: ApproveSubAccountMutation; productName: ProductName }>[] = [];
      if (apps.has('fb'))
        promises.push(
          fb
            .mutateAsync({ mainAccountEmail })
            .then((result) => ({ mutationResult: result, productName: 'FormBridge' })),
        );
      if (apps.has('kv'))
        promises.push(
          kv.mutateAsync({ mainAccountEmail }).then((result) => ({ mutationResult: result, productName: 'kViewer' })),
        );
      if (apps.has('km'))
        promises.push(
          km.mutateAsync({ mainAccountEmail }).then((result) => ({ mutationResult: result, productName: 'kMailer' })),
        );
      if (apps.has('pc'))
        promises.push(
          pc
            .mutateAsync({ mainAccountEmail })
            .then((result) => ({ mutationResult: result, productName: 'PrintCreator' })),
        );
      if (apps.has('dc'))
        promises.push(
          dc
            .mutateAsync({ mainAccountEmail })
            .then((result) => ({ mutationResult: result, productName: 'DataCollect' })),
        );
      if (apps.has('kb'))
        promises.push(
          kb.mutateAsync({ mainAccountEmail }).then((result) => ({ mutationResult: result, productName: 'kBackup' })),
        );
      return Promise.all(promises);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [pendingSubAccountsQueryPrefix],
      });
      // サービス一覧に表示される製品の権限にも影響するのでキャッシュクリアする
      queryClient.invalidateQueries({
        queryKey: [mainAndSubAccountsQueryPrefix],
      });
    },
  });
};

// 招待の辞退
const rejectSubAccountMutationPrefix = 'rejectSubAccount';
export const useRejectSubAccount = () => {
  const queryClient = useQueryClient();
  const fb = useMutation({
    mutationKey: [rejectSubAccountMutationPrefix, 'fb'],
    mutationFn: fbSdk.rejectSubAccount,
  });
  const kv = useMutation({
    mutationKey: [rejectSubAccountMutationPrefix, 'kv'],
    mutationFn: kvSdk.rejectSubAccount,
  });
  const km = useMutation({
    mutationKey: [rejectSubAccountMutationPrefix, 'km'],
    mutationFn: kmSdk.rejectSubAccount,
  });
  const pc = useMutation({
    mutationKey: [rejectSubAccountMutationPrefix, 'pc'],
    mutationFn: pcSdk.rejectSubAccount,
  });
  const dc = useMutation({
    mutationKey: [rejectSubAccountMutationPrefix, 'dc'],
    mutationFn: dcSdk.rejectSubAccount,
  });
  const kb = useMutation({
    mutationKey: [rejectSubAccountMutationPrefix, 'kb'],
    mutationFn: kbSdk.rejectSubAccount,
  });

  return useMutation<
    { mutationResult: RejectSubAccountMutation; productName: ProductName }[],
    ClientError,
    PendingSubAccount,
    unknown
  >({
    mutationFn: ({ mainAccountEmail, apps }) => {
      const promises: Promise<{ mutationResult: RejectSubAccountMutation; productName: ProductName }>[] = [];
      if (apps.has('fb'))
        promises.push(
          fb
            .mutateAsync({ mainAccountEmail })
            .then((result) => ({ mutationResult: result, productName: 'FormBridge' })),
        );
      if (apps.has('kv'))
        promises.push(
          kv.mutateAsync({ mainAccountEmail }).then((result) => ({ mutationResult: result, productName: 'kViewer' })),
        );
      if (apps.has('km'))
        promises.push(
          km.mutateAsync({ mainAccountEmail }).then((result) => ({ mutationResult: result, productName: 'kMailer' })),
        );
      if (apps.has('pc'))
        promises.push(
          pc
            .mutateAsync({ mainAccountEmail })
            .then((result) => ({ mutationResult: result, productName: 'PrintCreator' })),
        );
      if (apps.has('dc'))
        promises.push(
          dc
            .mutateAsync({ mainAccountEmail })
            .then((result) => ({ mutationResult: result, productName: 'DataCollect' })),
        );
      if (apps.has('kb'))
        promises.push(
          kb.mutateAsync({ mainAccountEmail }).then((result) => ({ mutationResult: result, productName: 'kBackup' })),
        );
      return Promise.all(promises);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [pendingSubAccountsQueryPrefix],
      });
    },
  });
};

// メインアカウントを選択済みかどうか
export const useIsMainAccountSelected = () => {
  const user = useAuthContext();
  return user?.mainAccountEmail != null;
};

// 自分自身を選択しているか
export const useIsMyselfSelected = () => {
  const user = useAuthContext();
  return !!(user?.email && user?.mainAccountEmail && user.email === user.mainAccountEmail);
};

// 自分自身の環境を1つでも持っているか
export const useIsHaveAnyMyAccount = (enabled = true) => {
  const { fb, kv, km, pc, dc, kb } = useMainAndSubAccounts(enabled);
  const isHaveAnyMyAccount =
    fb.data?.account != null ||
    kv.data?.account != null ||
    km.data?.account != null ||
    pc.data?.account != null ||
    dc.data?.account != null ||
    kb.data?.account != null;
  return {
    isHaveAnyMyAccount,
  };
};

type DropdownOption = { value: string | number; label: string; icon?: React.ReactNode };

// サブ管理者として選べるリストを取得
export const useSubAccountOptions = (enabled = true): { isPending: boolean; options: DropdownOption[] } => {
  const { fb, kv, km, pc, dc, kb, isAnyPending } = useMainAndSubAccounts(enabled);

  // 各製品の取得結果を重複を排除して1つにまとめる
  const options = useMemo(() => {
    const fbOptions =
      fb.data?.subAccounts.map<DropdownOption>((sub) => ({
        value: sub.mainAccount.email,
        label: sub.mainAccount.email,
      })) || [];
    const kvOptions =
      kv.data?.subAccounts.map<DropdownOption>((sub) => ({
        value: sub.mainAccount.email,
        label: sub.mainAccount.email,
      })) || [];
    const kmOptions =
      km.data?.subAccounts.map<DropdownOption>((sub) => ({
        value: sub.mainAccount.email,
        label: sub.mainAccount.email,
      })) || [];
    const pcOptions =
      pc.data?.subAccounts.map<DropdownOption>((sub) => ({
        value: sub.mainAccount.email,
        label: sub.mainAccount.email,
      })) || [];
    const dcOptions =
      dc.data?.subAccounts.map<DropdownOption>((sub) => ({
        value: sub.mainAccount.email,
        label: sub.mainAccount.email,
      })) || [];
    const kbOptions =
      kb.data?.subAccounts.map<DropdownOption>((sub) => ({
        value: sub.mainAccount.email,
        label: sub.mainAccount.email,
      })) || [];

    return uniqBy(
      (option) => option.value,
      [...fbOptions, ...kvOptions, ...kmOptions, ...pcOptions, ...dcOptions, ...kbOptions],
    );
  }, [fb.data, kv.data, km.data, pc.data, dc.data, kb.data]);

  return {
    isPending: isAnyPending,
    options,
  };
};

// サブ管理者を持っているか
export const useIsHaveAnySubAccount = () => {
  const { isPending, options } = useSubAccountOptions();
  const isHaveAnySubAccount = options.length > 0;

  return {
    isPending: isHaveAnySubAccount ? false : isPending,
    isHaveAnySubAccount,
  };
};

// 権限を持つアカウント選択の選択肢の数
// 自分自身のアドレスについては、どの製品の環境も持たなかったら0としてカウントする
export const useNumberOfSelectableAccounts = (enabled = true) => {
  const { isHaveAnyMyAccount } = useIsHaveAnyMyAccount(enabled);
  const { isPending, options: subAccountOptions } = useSubAccountOptions(enabled); // 全部同じクエリを使っているからこのisPendingだけを使う
  return {
    isPending,
    numberOfSelectableAccounts: (isHaveAnyMyAccount ? 1 : 0) + subAccountOptions.length,
  };
};

// アカウント選択の選択肢が一通りしかない人は自動で選択する
export const useAutomaticAccountSelect = (): { isPending: boolean } => {
  const user = useAuthContext();
  const isMainAccountSelected = useIsMainAccountSelected();
  const isMyselfSelected = useIsMyselfSelected();
  const { isHaveAnyMyAccount } = useIsHaveAnyMyAccount();
  const { isPending, options: subAccountOptions } = useSubAccountOptions(); // 全部同じクエリを使っているからこのisPendingだけを使う
  const { isHaveAnySubAccount } = useIsHaveAnySubAccount();
  const { isPending: isMutating, mutate: selectMainAccountMutate } = useSelectMainAccount();

  // 自分自身が選択されていない状態で、サブ管理者として1つも招待されていなければ、自動で自分自身を選択
  useEffect(() => {
    if (!isPending && !isMutating && user?.email && !isMyselfSelected && !isHaveAnySubAccount) {
      selectMainAccountMutate({ email: user.email });
    }
  }, [isHaveAnySubAccount, isPending, isMyselfSelected, selectMainAccountMutate, user?.email, isMutating]);

  // メインアカウント未選択で、自分自身の環境を1製品も持たず、1つだけサブ管理者として権限を持っていたら、自動でそのサブ管理者を選択
  useEffect(() => {
    if (!isPending && !isMutating && !isMainAccountSelected && !isHaveAnyMyAccount && subAccountOptions.length === 1) {
      selectMainAccountMutate({ email: subAccountOptions[0].value as string });
    }
  }, [isHaveAnyMyAccount, isPending, isMainAccountSelected, selectMainAccountMutate, subAccountOptions, isMutating]);

  return {
    isPending: isPending || isMutating,
  };
};

// アカウント選択画面(/app)以外の画面で、アカウントの選択がまだ行われていない場合は自動で自分自身を選択する
export const useAutomaticSelectMyselfIfUnselected = () => {
  const user = useAuthContext();
  const isMainAccountSelected = useIsMainAccountSelected();
  const { isPending, mutate: selectMainAccountMutate } = useSelectMainAccount();

  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    if (!isMainAccountSelected && !isPending && user && router.pathname !== '/app') {
      selectMainAccountMutate({ email: user.email });
    }
  }, [isMainAccountSelected, isPending, router.isReady, router.pathname, selectMainAccountMutate, user]);
};

// 選択した環境を示すaccount情報
export type SelectedAccount = {
  email: string;
  plan: Plan;
  trialExpiresAt: string | undefined | null;
};

// 引数の製品に対して、選択したアカウントで持っている環境を取得
export const useGetSelectedAccount = (
  mainAndSubs: MainAndSubAccountsQuery | undefined,
): SelectedAccount | undefined => {
  const user = useAuthContext();
  const isMyselfSelected = useIsMyselfSelected();

  // 引数が取れていなかったら環境なし
  if (mainAndSubs == null) return undefined;

  // アカウント選択をしていなかったら環境なし
  if (user?.mainAccountEmail == null) return undefined;

  // 自分自身を選択していて、自分自身の環境があるなら自分自身の環境を返す
  if (isMyselfSelected && mainAndSubs.account != null)
    return {
      email: mainAndSubs.account.email,
      plan: mainAndSubs.account.plan,
      trialExpiresAt: mainAndSubs.account.trialExpiresAt,
    };

  // サブ管理者を選択していて、選択したアカウントが引数の製品のサブ管理者として自分を招待していて承諾しているならその環境を返す
  const subAccount = mainAndSubs.subAccounts.find((sub) => sub.mainAccount.email === user.mainAccountEmail);
  if (subAccount) {
    return {
      email: subAccount.mainAccount.email,
      plan: subAccount.mainAccount.plan,
      trialExpiresAt: subAccount.mainAccount.trialExpiresAt,
    };
  }

  return undefined;
};

export type InvitedSubAccount = { email: string; apps: Set<string>; status: InvitationStatus };
export type AppsByStatus = {
  apps: Set<string>;
  status: InvitationStatus;
};
type InvitedSubAccountByStatus = {
  email: string;
  listOfAppsByStatus: AppsByStatus[];
};

// 自分自身が招待したサブ管理者のリストを取得
export const useSubAccountsInvitedByMyself = () => {
  const { fb, kv, km, pc, dc, kb, isAnyPending } = useMainAndSubAccounts();

  const subAccounts = useMemo<InvitedSubAccountByStatus[]>(() => {
    // 自分自身が所有する環境の期限が切れている製品はサブ管理者のリストに表示しない
    const notExpiredSubs = [
      fb.data?.account?.subAccount && !isAccountExpired(fb.data.account)
        ? { ...fb.data.account.subAccount, app: 'fb' }
        : undefined,
      kv.data?.account?.subAccount && !isAccountExpired(kv.data.account)
        ? { ...kv.data.account.subAccount, app: 'kv' }
        : undefined,
      km.data?.account?.subAccount && !isAccountExpired(km.data.account)
        ? { ...km.data.account.subAccount, app: 'km' }
        : undefined,
      pc.data?.account?.subAccount && !isAccountExpired(pc.data.account)
        ? { ...pc.data.account.subAccount, app: 'pc' }
        : undefined,
      dc.data?.account?.subAccount && !isAccountExpired(dc.data.account)
        ? { ...dc.data.account.subAccount, app: 'dc' }
        : undefined,
      kb.data?.account?.subAccount && !isAccountExpired(kb.data.account)
        ? { ...kb.data.account.subAccount, app: 'kb' }
        : undefined,
    ].filter((v): v is Exclude<typeof v, undefined> => v != null);

    // {メアド: {status: Set<製品>}}
    const appsByStatusByEmail = new Map<string, Map<InvitationStatus, Set<string>>>();

    notExpiredSubs.forEach(({ email, app, status }) => {
      const appsByStatus = appsByStatusByEmail.get(email);

      // 初めて出現したアドレス
      if (appsByStatus == null) {
        appsByStatusByEmail.set(email, new Map([[status, new Set([app])]]));
        return;
      }

      // すでに同じステータスのアドレスがある
      if (appsByStatus.has(status)) {
        appsByStatus.get(status)?.add(app);
        return;
      }

      // アドレスはあるがステータスが同じのがない
      appsByStatus.set(status, new Set([app]));
    });

    return Array.from(appsByStatusByEmail.entries()).map(([email, appsByStatus]) => ({
      email,
      listOfAppsByStatus: Array.from(appsByStatus.entries()).map(([status, apps]) => ({
        apps,
        status,
      })),
    }));
  }, [fb.data, kv.data, km.data, pc.data, dc.data, kb.data]);

  return {
    isPending: isAnyPending,
    subAccounts,
  };
};

// 自分自身がサブ管理者として招待できる製品の一覧を取得
export const useInvitableStatusAsSubAccount = () => {
  const { fb, kv, km, pc, dc, kb, isAnyPending } = useMainAndSubAccounts();

  return {
    isPending: isAnyPending,
    status: {
      fb: fb.data?.account != null && fb.data.account.subAccount == null && !isAccountExpired(fb.data.account),
      kv: kv.data?.account != null && kv.data.account.subAccount == null && !isAccountExpired(kv.data.account),
      km: km.data?.account != null && km.data.account.subAccount == null && !isAccountExpired(km.data.account),
      pc: pc.data?.account != null && pc.data.account.subAccount == null && !isAccountExpired(pc.data.account),
      dc: dc.data?.account != null && dc.data.account.subAccount == null && !isAccountExpired(dc.data.account),
      kb: kb.data?.account != null && kb.data.account.subAccount == null && !isAccountExpired(kb.data.account),
    },
  };
};

// 自分自身がサブ管理者として招待できる製品が一つでもあれば true
export const useCanAddSubAccount = () => {
  const { status, isPending } = useInvitableStatusAsSubAccount();
  const canAddSubAccount = useMemo(() => Object.values(status).some((b) => b), [status]);
  return {
    isPending,
    canAddSubAccount,
  };
};

// 自分自身のサブ管理者を招待する
const addSubAccountMutationPrefix = 'addSubAccount';
export const useAddSubAccount = () => {
  const queryClient = useQueryClient();
  const fb = useMutation({
    mutationKey: [addSubAccountMutationPrefix, 'fb'],
    mutationFn: fbSdk.addSubAccount,
  });
  const kv = useMutation({
    mutationKey: [addSubAccountMutationPrefix, 'kv'],
    mutationFn: kvSdk.addSubAccount,
  });
  const km = useMutation({
    mutationKey: [addSubAccountMutationPrefix, 'km'],
    mutationFn: kmSdk.addSubAccount,
  });
  const pc = useMutation({
    mutationKey: [addSubAccountMutationPrefix, 'pc'],
    mutationFn: pcSdk.addSubAccount,
  });
  const dc = useMutation({
    mutationKey: [addSubAccountMutationPrefix, 'dc'],
    mutationFn: dcSdk.addSubAccount,
  });
  const kb = useMutation({
    mutationKey: [addSubAccountMutationPrefix, 'kb'],
    mutationFn: kbSdk.addSubAccount,
  });

  return useMutation<
    { mutationResult: AddSubAccountMutation; productName: ProductName }[],
    ClientError,
    { email: string; apps: Set<string> },
    unknown
  >({
    mutationFn: ({ email, apps }) => {
      const promises: Promise<{ mutationResult: AddSubAccountMutation; productName: ProductName }>[] = [];
      if (apps.has('fb'))
        promises.push(
          fb.mutateAsync({ email }).then((result) => ({ mutationResult: result, productName: 'FormBridge' })),
        );
      if (apps.has('kv'))
        promises.push(kv.mutateAsync({ email }).then((result) => ({ mutationResult: result, productName: 'kViewer' })));
      if (apps.has('km'))
        promises.push(km.mutateAsync({ email }).then((result) => ({ mutationResult: result, productName: 'kMailer' })));
      if (apps.has('pc'))
        promises.push(
          pc.mutateAsync({ email }).then((result) => ({ mutationResult: result, productName: 'PrintCreator' })),
        );
      if (apps.has('dc'))
        promises.push(
          dc.mutateAsync({ email }).then((result) => ({ mutationResult: result, productName: 'DataCollect' })),
        );
      if (apps.has('kb'))
        promises.push(kb.mutateAsync({ email }).then((result) => ({ mutationResult: result, productName: 'kBackup' })));
      return Promise.all(promises);
    },

    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [mainAndSubAccountsQueryPrefix],
      });
    },
  });
};

// 自分自身のサブ管理者を削除する
const deleteSubAccountMutaionPrefix = 'deleteSubAccount';
export const useDeleteSubAccount = () => {
  const queryClient = useQueryClient();
  const fb = useMutation({
    mutationKey: [deleteSubAccountMutaionPrefix, 'fb'],
    mutationFn: fbSdk.deleteSubAccount,
  });
  const kv = useMutation({
    mutationKey: [deleteSubAccountMutaionPrefix, 'kv'],
    mutationFn: kvSdk.deleteSubAccount,
  });
  const km = useMutation({
    mutationKey: [deleteSubAccountMutaionPrefix, 'km'],
    mutationFn: kmSdk.deleteSubAccount,
  });
  const pc = useMutation({
    mutationKey: [deleteSubAccountMutaionPrefix, 'pc'],
    mutationFn: pcSdk.deleteSubAccount,
  });
  const dc = useMutation({
    mutationKey: [deleteSubAccountMutaionPrefix, 'dc'],
    mutationFn: dcSdk.deleteSubAccount,
  });
  const kb = useMutation({
    mutationKey: [deleteSubAccountMutaionPrefix, 'kb'],
    mutationFn: kbSdk.deleteSubAccount,
  });

  return useMutation<
    { mutationResult: DeleteSubAccountMutation; productName: ProductName }[],
    ClientError,
    Set<string>,
    unknown
  >({
    mutationFn: (apps) => {
      const promises: Promise<{ mutationResult: DeleteSubAccountMutation; productName: ProductName }>[] = [];
      if (apps.has('fb'))
        promises.push(fb.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'FormBridge' })));
      if (apps.has('kv'))
        promises.push(kv.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'kViewer' })));
      if (apps.has('km'))
        promises.push(km.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'kMailer' })));
      if (apps.has('pc'))
        promises.push(pc.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'PrintCreator' })));
      if (apps.has('dc'))
        promises.push(dc.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'DataCollect' })));
      if (apps.has('kb'))
        promises.push(kb.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'kBackup' })));
      return Promise.all(promises);
    },

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [mainAndSubAccountsQueryPrefix],
      }),
  });
};

// 自分自身とサブ管理者を入れ替える
const swapOwnerMutationPrefix = 'swapOwner';
export const useSwapOwnerWithSubAccount = () => {
  const queryClient = useQueryClient();
  const fb = useMutation({
    mutationKey: [swapOwnerMutationPrefix, 'fb'],
    mutationFn: fbSdk.swapOwnerWithSubAccount,
  });
  const kv = useMutation({
    mutationKey: [swapOwnerMutationPrefix, 'kv'],
    mutationFn: kvSdk.swapOwnerWithSubAccount,
  });
  const km = useMutation({
    mutationKey: [swapOwnerMutationPrefix, 'km'],
    mutationFn: kmSdk.swapOwnerWithSubAccount,
  });
  const pc = useMutation({
    mutationKey: [swapOwnerMutationPrefix, 'pc'],
    mutationFn: pcSdk.swapOwnerWithSubAccount,
  });
  const dc = useMutation({
    mutationKey: [swapOwnerMutationPrefix, 'dc'],
    mutationFn: dcSdk.swapOwnerWithSubAccount,
  });
  const kb = useMutation({
    mutationKey: [swapOwnerMutationPrefix, 'kb'],
    mutationFn: kbSdk.swapOwnerWithSubAccount,
  });

  return useMutation<
    { mutationResult: SwapOwnerWithSubAccountMutation; productName: ProductName }[],
    ClientError,
    InvitedSubAccount,
    unknown
  >({
    mutationFn: (subAccount) => {
      const promises: Promise<{ mutationResult: SwapOwnerWithSubAccountMutation; productName: ProductName }>[] = [];
      if (subAccount.apps.has('fb'))
        promises.push(fb.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'FormBridge' })));
      if (subAccount.apps.has('kv'))
        promises.push(kv.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'kViewer' })));
      if (subAccount.apps.has('km'))
        promises.push(km.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'kMailer' })));
      if (subAccount.apps.has('pc'))
        promises.push(pc.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'PrintCreator' })));
      if (subAccount.apps.has('dc'))
        promises.push(dc.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'DataCollect' })));
      if (subAccount.apps.has('kb'))
        promises.push(kb.mutateAsync({}).then((result) => ({ mutationResult: result, productName: 'kBackup' })));
      return Promise.all(promises);
    },

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [mainAndSubAccountsQueryPrefix],
      }),
  });
};
